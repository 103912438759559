

<template>
	<div class="tcontainer">
		<div style="margin: 10px;">
			<Calendar @hide="loadLazyData" placeholder="Select Range" v-model="dateRange" selectionMode="range"
				dateFormat="dd/mm/yy" />
		</div>

		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				<div>
					This Month Billing: 
					&#8377; {{ sums?.superCenter ? sums?.thisMonthCenterPrice : sums?.thisMonthBilling }}
					(Reports: {{ sums?.thisMonthReports }})
				</div>
				<div>
					Last Month Billing: 
					&#8377; {{ sums?.superCenter ? sums?.lastMonthCenterPrice : sums?.lastMonthBilling }}
					(Reports: {{ sums?.lastMonthReports }})
				</div>
			</div>
			<div class="title">Manage EEG Billing</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Report Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Report Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['patient_name'] }}
					</div>
				</template>
			</Column>
			<Column field="center_name" header="Center Name" filterField="center_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Center Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['center_name'] }}
					</div>
				</template>
			</Column>
			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Status</option>

							<option value="pending">Pending</option>
							<option value="reported">Reported</option>
							<option value="printed">Printed</option>
							<option value="review">Review</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['status'] }}
					</div>
				</template>
			</Column>
			<Column v-if="sums?.superCenter" field="price" header="Price" filterField="price" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Price" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['price'] }}
					</div>
				</template>
			</Column>
			<Column v-if="!sums?.superCenter" field="agent_price" header="Price" filterField="agent_price" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Price" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['agent_price'] }}
					</div>
				</template>
			</Column>
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['created_at'] }}
					</div>
				</template>
			</Column>
		</DataTable>

	</div>
</template>

<script>
import Calendar from 'primevue/calendar';

export default {
	components: {
		Calendar
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			saving: false,
			lazyParams: {},
			dateRange: "",
			sums: {},
			filters: {
				'id': { value: '', matchMode: 'like' },
				'center_id': { value: '', matchMode: 'like' },
				'center_name': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'patient_age': { value: '', matchMode: 'like' },
				'patient_gender': { value: '', matchMode: 'like' },
				'ref_doctor_name': { value: '', matchMode: 'like' },
				// 'test_id': {value: '', matchMode: 'like'},
				'test_date': { value: '', matchMode: 'like' },
				'history': { value: '', matchMode: 'like' },
				'reg_no': { value: '', matchMode: 'like' },
				'mobile_no': { value: '', matchMode: 'like' },
				'assign_doc_id': { value: '', matchMode: 'like' },
				'assign_doc_ids': { value: '', matchMode: 'like' },
				'images': { value: '', matchMode: 'like' },
				'report': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
				'urgent': { value: '', matchMode: 'like' },
				'review_notes': { value: '', matchMode: 'like' },
				'review_done': { value: '', matchMode: 'like' },
				'notes': { value: '', matchMode: 'like' },
				'price': { value: '', matchMode: 'like' },
				'agent_price': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'reported_at': { value: '', matchMode: 'like' },
				'accepted_at': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				center_id: '',
				patient_name: '',
				patient_age: '',
				patient_gender: '',
				ref_doctor_name: '',
				// test_id: '',
				test_date: '',
				history: '',
				reg_no: '',
				mobile_no: '',
				assign_doc_id: '0',
				assign_doc_ids: '',
				images: '',
				report: '',
				status: 'pending',
				urgent: 'n',
				review_notes: '',
				review_done: 'n',
				notes: '',
				price: '0',
				created_at: '',
				reported_at: '',
				accepted_at: '',
			},
			fvalidator: {
				center_id: 'required',
				patient_name: 'required|max:30',
				patient_age: 'required|max:15',
				patient_gender: 'required|in:male,female',
				ref_doctor_name: 'required|max:50',
				// test_id: 'required',
				test_date: 'required',
				history: 'required',
				reg_no: 'required|max:40',
				mobile_no: 'required|max:15',
				assign_doc_id: '',
				assign_doc_ids: 'required|max:255',
				images: '',
				report: '',
				status: 'required|in:pending,reported,printed,review',
				urgent: 'required|in:y,n',
				review_notes: '',
				review_done: 'required|in:y,n',
				notes: '',
				price: 'required',
				created_at: 'required',
				reported_at: '',
				accepted_at: '',
			}
		}
	},
	mounted() {

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

		this.get("agent/load-recent-eeg-billing").then(res=>{
			this.sums = res
		});

	},
	methods: {
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}

			params.sums = [['agent_price', 'totalPrice']]
			params.joins = [
				{
					"fTable": "centers",
					"fKey": "center_id",
					"fField": [["name", "center_name"]]
				},
			]

			// new Date().toISOString()
			if (this.dateRange[0] && this.dateRange[1]) {
				// params.filters['created_at'] = { value: [this.dateRange[0].toISOString(), this.dateRange[1].toISOString()], matchMode: "between" }
				params.filters['created_at'] = { value: [this.format(this.dateRange[0], "YYYY-MM-DD")+' 00:00:00', this.format(this.dateRange[1], "YYYY-MM-DD")+" 23:59:59"], matchMode: "between" };
			}
			this.post("agent/load-eeg-reports", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
					// this.sums = res.sums;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped></style>